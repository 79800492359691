<template>
    <nav
        class="lni-c-language-links__wrapper"
        aria-labelledby="language-nav-label"
    >
        <span
            id="language-nav-label"
            class="lni-u-visually-hidden lni-u-type--xxs"
        >{{ label }}</span>
        <div class="lni-c-language-links lni-u-flex lni-u-mhauto">
            <div class="lni-c-language-links__container lni-u-flex">
                <!-- TODO: Replace with lni-svg-icon? -->
                <svg

                    class="lni-c-language-bar__icon"
                    width="50"
                    height="50"
                    viewBox="0 0 150 150"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke-linejoin="round"
                >
                    <path
                        d="M 73.568765,23.524871 27.855113,8.3264467 V 91.917787 l 45.713652,15.198433 z"
                        class="fill--accent stroke--accent"
                        stroke-width="2.9"
                    />
                    <path
                        d="M 73.568765,23.524871 124.04425,6.4266437 V 90.01798 l -50.475485,17.09823 z"
                        class="fill--base stroke--accent"
                        stroke-width="2.9"
                    />
                    <path
                        d="M 16.4267,42.5229 73.568765,23.524871 V 107.11621 L 16.4267,126.11424 Z"
                        class="fill--base stroke--accent"
                        stroke-width="2.9"
                    />
                    <g class="fill--accent">
                        <path d="m 34.426462,50.522799 c 2.986197,2.410668 11.573018,-1.595506 13.999813,-1.999971 0.229716,-0.05741 3.170354,2.298667 0,4.999935 -2.669079,2.274165 -5.397483,2.794365 -7.681437,2.936549 -2.995367,0.186479 -5.255659,-2.494565 -6.318376,-5.936513 z" />
                        <path d="m 27.426556,66.52259 c -0.05148,-0.763413 2.031881,-0.554495 3.130972,-0.864926 8.335336,-2.354248 19.326881,-5.835381 20.868711,-7.134969 2.690617,-1.01798 7.952716,-3.964566 7.999891,-2.999961 0,0 0.234932,1.376082 0,1.999975 -2.926956,7.773312 -7.015167,14.125514 -10.999851,20.999722 l 16.999772,6.999906 c 0.575334,1.091309 1.120074,2.213216 0.999985,3.999949 0.765556,1.713842 -5.210021,-0.975725 -9.989318,-3.986612 C 53.184535,83.486846 49.384369,80.70944 47.426289,79.522416 41.865676,88.407079 32.512108,99.98713 27.426556,103.5221 h -2.499969 c 5.447867,-6.0835 17.371276,-18.779904 27.499637,-40.999456 -0.90238,-0.227978 -9.71207,2.925644 -14.999804,3.999946 -1.366568,0.322238 -0.962961,1.234547 -0.999985,1.999971 -3.897399,2.486699 -8.741227,1.836414 -8.999879,-1.999971 z" />
                    </g>
                    <path
                        d="M 133.56793,43.472804 73.568765,23.524871 v 83.591339 l 59.999165,19.94794 z"
                        class="fill--accent stroke--accent"
                        stroke-width="2.9"
                    />
                    <path
                        class="fill--accent"
                        d="m 113.07065,127.52593 -12.80434,0.11819 3.42202,5.59497 c -21.387121,13.75038 -45.057202,9.98872 -62.541733,-0.23286 -0.326963,-0.19112 -0.716816,-0.24487 -1.083505,-0.14936 -0.366533,0.0953 -0.679969,0.33187 -0.871638,0.65768 -0.399222,0.67888 -0.171021,1.55204 0.509663,1.95009 18.18067,10.62854 43.094996,14.56067 65.475283,0.20723 l 3.4051,5.56712 z"
                    />
                    <path
                        class="fill--base"
                        d="M 123.56029,102.33583 112.82571,98.81419 110.04067,88.698443 95.11501,83.801854 92.32997,92.090244 81.865783,88.657311 96.73737,48.014984 108.68871,51.935802 Z M 107.52602,79.527416 102.57784,61.578833 97.62966,76.280766 Z"
                    />
                </svg>

                <ul
                    ref="wrapper"
                    class="lni-c-language-links__list lni-u-full-width lni-u-flex lni-u-justify-between"
                >
                    <li
                        v-for="lang in languages"
                        :key="lang.path"
                        ref="language-list-item"
                        class="lni-c-language-links__list-item"
                        :class="!lang.show ? 'lni-u-hide' : null"
                    >
                        <a
                            :lang="lang.lang"
                            class="lni-c-language-links__link"
                            :href="getHref(lang)"
                            @click="onLangClick(lang, $event)"
                        >{{ lang.label }}
                        </a>
                    </li>
                    <li
                        ref="last"
                        class="lni-c-language-links__list-item  lni-u-flex lni-u-items-center lni-u-justify-center"
                    >
                        <a
                            v-show="!overflowLanguages.length"
                            class="lni-c-language-links__link"
                            :href="languageHelpLink"
                        >{{ languageHelpLabel }}</a>

                        <button
                            v-show="overflowLanguages.length"
                            ref="overflowButton"
                            aria-controls="overflow_langs"
                            :aria-expanded="showOverflowLangs ? 'true' : 'false'"
                            class="lni-c-language-links__overflow-button lni-u-flex lni-u-text-center lni-c-language-links__link lni-u-button-reset lni-u-type--xxs lni-u-line-height--tight"
                            @click="showOverflowLangs = !showOverflowLangs"
                        >
                            <span class="lni-u-mr1">{{ otherLanguagesLabel }}</span>
                            <span
                                class="lnicon--caret--down"
                                aria-hidden="true"
                            />
                        </button>
                        <ul
                            v-show="showOverflowLangs && overflowLanguages.length"
                            id="overflow_langs"
                            class="lni-c-language-links__overflow-list"
                        >
                            <li
                                v-for="lang in languages"
                                :key="`overflow_${lang.path}`"
                                :class="lang.show ? 'lni-u-hide' : null"
                                class="lni-c-language-links__overflow-item"
                            >
                                <a
                                    :lang="lang.lang"
                                    class="lni-c-language-links__overflow-link lni-c-language-links__link"
                                    :href="getHref(lang)"
                                    @click="onLangClick(lang, $event)"
                                >{{ lang.label }}</a>
                            </li>
                            <li
                                class="lni-c-language-links__overflow-item"
                            >
                                <a
                                    class="lni-c-language-links__overflow-link lni-c-language-links__link lni-c-language-links__language-help-link"
                                    :href="languageHelpLink"
                                >{{ languageHelpLabel }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
// TODO: Move into CMS and provide via nav API?
const supportedLanguages = [
    {
        lang: 'en',
        path: 'english',
        label: 'English',
    },
    {
        lang: 'es',
        path: 'spanish',
        label: 'Español',
    },
    {
        lang: 'km',
        path: 'cambodian',
        label: 'ប្រទេសកម្ពុជា',
    },
    {
        lang: 'zh-Hans',
        path: 'chinese-simplified',
        label: '中国 - 简体',
    },
    {
        lang: 'zh-Hant',
        path: 'chinese-traditional',
        label: '中文繁體',
    },
    {
        lang: 'ko',
        path: 'korean',
        label: '한국어',
    },
    {
        lang: 'ru',
        path: 'russian',
        label: 'русский',
    },
    {
        lang: 'so',
        path: 'somali',
        label: 'Soomaali',
    },
    {
        lang: 'vi',
        path: 'vietnamese',
        label: 'tiếng Việt',
    },
];

export default {
    name: 'LniLanguageBar',
    props: {
        origin: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        otherLanguagesLabel: {
            type: String,
            required: true,
        },
        languageHelpLabel: {
            type: String,
            required: true,
        },
        languageHelpUrl: {
            type: String,
            required: true,
        },
        storage: {
            type: Object,
            required: true,
        },
        multilingualEnabled: {
            type: Boolean,
            required: true,
        },
        /*
         * Used by SPAs to provide the link for English/Spanish switch
         * Otherwise it will link to CMS home page
         * { url: '', query: {}}
         * url = base URL for the app (domain and pathname ex. /ScheduleOfficeVisit/ or https://secure.lni.wa.gov/ScheduleOfficeVisit/)
         * query = URL parameters in key/value pairs (ex. {office: 'Olympia-Tumwater'})
         * Do not include lang parameter in query object...it will be appended by the English/Spanish switch
         */
        secureHref: {
            type: () => Object | null,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            showOverflowLangs: false,
            supportedLanguages,
            languages: [],
        };
    },
    computed: {
        overflowLanguages() {
            return this.languages.filter(lang => !lang.show);
        },
        languageHelpLink() {
            return `${this.origin}${this.languageHelpUrl}`;
        },
        currentLanguage() {
            return this.$oneLni.getLanguage();
        },
    },
    created() {
        // chocking on comma dangle?
        this.languages = this.supportedLanguages.filter(
            lang => lang.lang !== this.currentLanguage || !this.multilingualEnabled,
        ).map(lang => ({
            ...lang,
            show: true,
        }));
    },
    mounted() {
        window.addEventListener('resize', this.handleOverflow);
        this.handleOverflow();
    },
    unmounted() {
        window.removeEventListener('resize', this.handleOverflow);
    },
    methods: {
        getHref(lang) {
            if (this.secureHref && ['es', 'en'].includes(lang.lang)) {
                const params = Object.keys(this.secureHref.query);
                let href = `${this.secureHref.url}${params.length ? '?' : ''}`;
                for (let i = 0; i < params.length; i++) {
                    href += `${params[i]}=${this.secureHref.query[params[i]]}${i < params.length - 1 ? '&' : ''}`;
                }
                if (lang.lang === 'es') {
                    href += params.length ? '&lang=es' : '?lang=es';
                }
                return href;
            }

            const loc = window.location;
            if (lang.lang === 'en' && this.multilingualEnabled) {
                const englishPath = loc.pathname.split('/').filter(dir => dir !== 'es').join('/');
                return `${loc.origin}${englishPath}`;
            }
            if (lang.lang === 'es' && this.multilingualEnabled) {
                return `${loc.origin}/es${loc.pathname}`;
            }
            return `${this.origin}/agency/languages/${lang.path}`;
        },
        handleOverflow() {
            this.languages = [...this.languages].map(lang => ({
                ...lang,
                show: true,
            }));
            const offset = 16; // Prevents bug that hides last item even when there is room
            const lastItemWidth = this.$refs.last.scrollWidth;
            const {
                offsetWidth: parentOffsetWidth,
                scrollLeft,
            } = this.$refs.wrapper;
            const available = scrollLeft + parentOffsetWidth;


            const newLangs = [...this.languages];

            this.$nextTick(() => {
                this.languages.forEach((item, index) => {
                    const {
                        offsetWidth,
                        offsetLeft,
                    } = this.$refs['language-list-item'][index];
                    const isOutside = (offsetLeft + offsetWidth) > available - lastItemWidth + offset;

                    if (isOutside) {
                        newLangs[index].show = false;
                    } else {
                        newLangs[index].show = true;
                    }
                });

                this.languages = newLangs;

            });
        },
        onLangClick(lang) {
            if (['es', 'en'].includes(lang.lang) && this.multilingualEnabled) {
                this.$oneLni.setLanguage(lang.lang);
            }

            /*
             * TODO: Is there a better way to handle this?
             * Link clicks are not working on secure without forcing router to refresh
             */
            if (this.$router) {
                this.$router.go();
            }
        },
    },
};
</script>